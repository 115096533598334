.app {
  font-family: "Roboto", sans-serif;
  text-align: center;
  min-height: 100vh;
  background: #161e2e; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #161e2e,
    #0f131a
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #161e2e,
    #0f131a
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.app_body {
  max-width: 1200px;
  height: calc(100vh - 60px);
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  h1 {
    max-width: 500px;
    text-align: left;
    font-size: 38px;
    font-weight: 700;
  }
  p {
    max-width: 600px;
    text-align: left;
  }
}

.app_link {
  color: #61dafb;
}
.app_body_left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 60px;
  width: 50%;
}
.app_body_right {
  width: 50%;
}
.app_image {
  height: 80vh;
}
.app_footer {
  padding: 10px 40px;
  height: 40px;
  color: #ffffff;
}
.app_footer p {
  display: flex;
  width: 100%;
  font-size: 14px;
  margin-left: auto;
  flex-direction: row;
  justify-content: flex-end;
}
.app_footer_mobile {
  display: none;
}

@media (max-width: 768px) {
  .app {
    height: 100%;
    padding: 20px;
  }
  .app_body {
    flex-direction: column;
    height: 100%;
  }
  .app_body_left {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    h1 {
      font-size: 28px;
    }
    p {
      max-width: 600px;
      text-align: center;
    }
  }
  .app_body_right {
    width: 100%;
  }
  .app_footer {
    display: none;
  }
  .app_footer_mobile {
    margin-top: 20px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #ffffff;
    p {
      margin: 4px;
    }
  }
}
